



























import { t } from "@/i18n";
import { defineComponent, PropType } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomText,
  AtomButton,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";

const css = bemBuilder("onboarding-publish-banner");

export default defineComponent({
  name: "OnboardingPublishBanner",
  components: {
    AtomText,
    AtomButton,
  },
  props: {
    onClick: {
      type: Function as PropType<() => {}>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
