





























































import { t } from "@/i18n";
import { defineComponent, getCurrentInstance, ref } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomIcon,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  MolVideoModal,
} from "@/v2/new-design-system";
import { onboardingTrack } from "@/v2/module/onboarding/track";
import { EventEnum } from "@/v2/enum";

const css = bemBuilder("onboarding-welcome-banner");

export default defineComponent({
  name: "OnboardingWelcomeBanner",
  components: {
    AtomIcon,
    AtomText,
    MolGuideLink,
    MolVideoModal,
  },
  props: {
    country: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();
    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;
    const showModal = ref<boolean>(false);
    const duration = ref(0);

    // TODO: repot to sentry when 'report()' method is available
    const listener = (event: MessageEvent) => {
      const correctSource = event.origin.includes("https://fast.wistia.net");
      const data = JSON.parse(event.data);

      if (correctSource && data?.method === "updateProperties") {
        const arg = data.args[0];

        if (arg._time) {
          duration.value = arg._time;
        }
      }
    };

    const onShowVideo = () => {
      showModal.value = true;

      window.addEventListener("message", listener);

      onboardingTrack(
        analytics,
        EventEnum.CHATFOOD_INTRO_VIDEO_STARTED,
        props.businessId,
        {
          onboarding_chatfood_intro_video: "Video modal opens",
        }
      );
    };

    const onCloseVideo = () => {
      showModal.value = false;

      window.removeEventListener("message", listener);

      onboardingTrack(
        analytics,
        EventEnum.CHATFOOD_INTRO_VIDEO_FINISHED,
        props.businessId,
        {
          duration: Math.round(duration.value),
          onboarding_chatfood_intro_video: "Video modal closes",
        }
      );
    };

    return {
      t,
      css,
      showModal,
      onShowVideo,
      onCloseVideo,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
    };
  },
});
