import { graphql } from "../graphql";
import query from "./query.gql";
import { RepoErrorEnum } from "../repo-error.enum";

export async function fetchBusinessOnboardingStatus(
  businessId: string
): Promise<
  | "New"
  | "Active Implementation"
  | "Live"
  | "Cancelled"
  | "Converted"
  | "Churned"
> {
  try {
    const response = await graphql.query({
      query,
      variables: {
        id: businessId,
      },
    });

    return response?.data?.business.onboardingStatus || "";
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
