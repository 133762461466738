import Base from "./Base";

/** Constructor to normalize outsider data */
class Inventory extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} inventory
   */
  constructor(inventory = {}) {
    const keys = {
      outletId: ["outletId", "outlet_id"],
      outletName: ["outletName", "outlet_name"],
      isAvailable: ["isAvailable", "available", "in_stock"],
    };

    super(keys, inventory);
  }
}

export default Inventory;
