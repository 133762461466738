import Base from "./Base";
import MenuCategory from "./MenuCategory";
import Source from "./Source";
import { is } from "ramda";

/** Constructor to normalize outsider data */
class Menu extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} menu
   */
  constructor(menu = {}) {
    const keys = {
      id: ["id"],
      isDefault: ["isDefault", "is_default"],
      name: ["name"],
      state: ["state"], // draft or live
      businessId: ["businessId", "business_id"],
      businessName: ["businessName", "business_name"],
      partnerId: ["partnerId", "partner_id"],
      partnerName: ["partnerName", "partner_name"],
      notes: ["notes"],
      source: ["source"],
      summary: ["summary"],
      icon: ["icon"],
      image: ["image"],
      hideSnoozedItems: ["hideSnoozedItems", "hide_snoozed_items"],
    };

    super(keys, menu);

    // Has many categories
    if (is(Array, menu.categories)) {
      this.categories = menu.categories.map((obj) => new MenuCategory(obj));
    }

    // Ensure summary is a string
    this.summary = menu.summary ?? "";

    // Source relationship
    if (menu.source) {
      this.source = new Source(menu.source);
    }
  }

  /**
   * Return if it's a ChatFood menu.
   *
   * @returns {Boolean}
   */
  get isChatFood() {
    return this.partnerId === 1;
  }

  /**
   * Return if menu is published or not.
   *
   * @returns {Boolean}
   */
  get isPublished() {
    return this.state === "live";
  }

  /**
   * Return the menu state well formatted.
   *
   * @returns {String}
   */
  get formattedState() {
    if (this.state === "live") {
      return "Live";
    }

    return "Draft";
  }

  /**
   * Build payload to create a resource based in the API specs.
   *
   * @return {Object}
   */
  toCreatePayload() {
    return {
      partner_id: this.partnerId,
      name: this.name,
      notes: this.notes,
    };
  }

  /**
   * Build payload to update a resource based in the API specs.
   *
   * @return {Object}
   */
  toUpdatePayload() {
    return {
      name: this.name,
      notes: this.notes,
    };
  }
}

export default Menu;
