import Base from "./Base";
import ModifierList from "./ModifierList";
import Inventory from "./Inventory";
import Source from "./Source";
import { is, isNil } from "ramda";

/** Constructor to normalize outsider data */
class Item extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} item
   */
  constructor(item = {}) {
    const keys = {
      id: ["id"],
      businessId: ["businessId", "business_id"],
      businessName: ["businessName", "business_name"],
      name: ["name"],
      description: ["description"],
      photo: ["photo"],
      isModifier: ["isModifier", "is_modifier"],
      amount: ["amount"],
      createdAt: ["createdAt", "created_at"],
      sku: ["sku"],
      source: ["source"],
    };

    super(keys, item);

    // Set new price attr to null
    // Attr to be used during price updation action
    this.newAmount = null;

    // Modifier Lists relationship
    if (is(Array, item.modifier_lists)) {
      this.modifierLists = item.modifier_lists.map(
        (obj) => new ModifierList(obj)
      );
    }

    // Inventories relationship
    if (is(Array, item.inventories)) {
      this.inventories = item.inventories.map((obj) => new Inventory(obj));
    }

    // Source relationship
    if (item.source) {
      this.source = new Source(item.source);
    }
  }

  /**
   * Set the new amount as the amount.
   *
   * @returns {void}
   */
  applyNewAmount() {
    if (isNil(this.newAmount)) {
      this.newAmount = this.amount;
    }

    this.amount = this.newAmount;
    this.newAmount = null;
  }

  /**
   * Build payload to create a resource based in the API specs.
   *
   * @return {Object}
   */
  toCreatePayload() {
    return {
      name: this.name,
      description: this.description,
      is_modifier: this.isModifier,
      amount: this.amount,
      sku: this.sku,
    };
  }

  /**
   * Build payload to update a resource based in the API specs.
   *
   * @return {Object}
   */
  toUpdatePayload() {
    return this.toCreatePayload();
  }

  /**
   * Build payload to update the price of a resource based in the API specs.
   *
   * @return {Object}
   */
  toUpdatePricePayload() {
    return {
      modifier_id: this.id,
      new_amount: this.newAmount,
    };
  }
}

export default Item;
