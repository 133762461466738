


































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomIcon,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { defineComponent, PropType } from "@vue/composition-api";
import { IOnboardingNextStepCardProps } from "./props";

const css = bemBuilder("onboarding-next-step-card");

export default defineComponent<IOnboardingNextStepCardProps>({
  name: "OnboardingNextStepCard",
  components: {
    AtomText,
    AtomIcon,
  },
  props: {
    estimateMins: {
      type: Number,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    route: {
      type: Object as PropType<IOnboardingNextStepCardProps["route"]>,
      required: true,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
