import Base from "./Base";
import Item from "./Item";
import Source from "./Source";
import { is } from "ramda";

/** Constructor to normalize outsider data */
class ModifierList extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} modifierList
   */
  constructor(modifierList = {}) {
    const keys = {
      id: ["id"],
      businessId: ["businessId", "business_id"],
      businessName: ["businessName", "business_name"],
      name: ["name"],
      displayName: ["displayName", "display_name"],
      minSelectedModifiers: ["minSelectedModifiers", "min_selected_modifiers"],
      maxSelectedModifiers: ["maxSelectedModifiers", "max_selected_modifiers"],
      selectionType: ["selectionType", "selection_type"],
      itemsCount: ["itemsCount", "items_count"],
      createdAt: ["createdAt", "created_at"],
      source: ["source"],
    };

    super(keys, modifierList);

    // Modifiers relationship
    if (is(Array, modifierList.modifiers)) {
      this.modifiers = modifierList.modifiers.map((obj) => new Item(obj));
    }

    // Source relationship
    if (modifierList.source) {
      this.source = new Source(modifierList.source);
    }
  }

  /**
   * Build payload to create a resource based in the API specs.
   *
   * @return {Object}
   */
  toCreatePayload() {
    return {
      name: this.name,
      display_name: this.displayName,
      min_selected_modifiers: this.minSelectedModifiers,
      max_selected_modifiers: this.maxSelectedModifiers,
      selection_type: this.selectionType,
    };
  }

  /**
   * Build payload to update a resource based in the API specs.
   *
   * @return {Object}
   */
  toUpdatePayload() {
    return {
      name: this.name,
      display_name: this.displayName,
      min_selected_modifiers: this.minSelectedModifiers,
      max_selected_modifiers: this.maxSelectedModifiers,
      selection_type: this.selectionType,
    };
  }

  /**
   * Build payload to update a resource based in the API specs.
   *
   * @return {Object}
   */
  toOverrideSettingsPayload() {
    return {
      modifier_list_id: this.id,
      min_selected_modifiers: this.minSelectedModifiers,
      max_selected_modifiers: this.maxSelectedModifiers,
      selection_type: this.selectionType,
    };
  }
}

export default ModifierList;
