import Base from "./Base";
import Menu from "./Menu";
import Item from "./Item";
import OutletAvailability from "./OutletAvailability";
import { is } from "ramda";

/** Constructor to normalize outsider data */
class MenuCategory extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} category
   */
  constructor(category = {}) {
    const keys = {
      id: ["id"],
      name: ["name"],
      menuId: ["menuId", "menu_id"],
      ordinal: ["ordinal"],
      createdAt: ["createdAt", "created_at"],
      updatedAt: ["updatedAt", "updated_at"],
    };

    super(keys, category);

    // Belongs to a menu
    if (is(Object, category.menu)) {
      this.menu = new Menu(category.menu);
    }

    // Has many items
    this.items = [];
    if (is(Array, category.items)) {
      this.items = category.items.map((obj) => new Item(obj));
    }

    // Outlets Availability relationship
    if (is(Array, category.visibility)) {
      this.availabilities = category.visibility.map(
        (obj) => new OutletAvailability(obj)
      );
    }
  }

  /**
   * Build payload to create a resource based in the API specs.
   *
   * @return {Object}
   */
  toCreatePayload() {
    return {
      name: this.name,
    };
  }
}

export default MenuCategory;
