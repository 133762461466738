















import { t } from "@/i18n";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { IOnboardingProgressBarProps } from "./props";

const css = bemBuilder("onboarding-progress-bar");

export default defineComponent<IOnboardingProgressBarProps>({
  name: "OnboardingProgressBar",
  components: {
    AtomText,
  },
  props: {
    progress: {
      type: String as PropType<"25" | "50" | "75">,
      default: "25",
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
