import Base from "./Base";

/** Constructor to normalize outsider data */
class Source extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} source
   */
  constructor(source = {}) {
    const keys = {
      id: ["id"],
      name: ["name"],
      isEditable: ["isEditable", "is_editable"],
      isIntegration: ["isIntegration", "is_integration"],
    };

    super(keys, source);
  }
}

export default Source;
