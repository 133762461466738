import Base from "./Base";

/** Constructor to normalize outsider data */
class OutletAvailability extends Base {
  /**
   * Class Constuctor.
   *
   * @param {Object} outletAvailability
   */
  constructor(outletAvailability = {}) {
    const keys = {
      outletId: ["outletId", "outlet_id"],
      outletName: ["outletName", "outlet_name"],
      isAvailable: ["isAvailable", "is_available", "is_visible"],
    };

    super(keys, outletAvailability);
  }
}

export default OutletAvailability;
