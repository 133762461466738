

































































































































































































import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
  watch,
} from "@vue/composition-api";
import { t } from "@/i18n";
import { Toast } from "@/design-system";
import { useHelpers } from "@/v2/composable/use-helpers";
import {
  AtomLoading,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  MolGuideLink,
  MolGuideLinkArticleEnum,
} from "@/v2/new-design-system";
import { MolClipboardCopy } from "@/v2/new-design-system/mol/clipboard-copy";
import { fetchOnboardingSteps } from "@/v2/repo/fetch-onboarding-steps";
import { updatePublishBusiness } from "@/v2/repo/update-publish-business";
import { bemBuilder } from "@/v2/util/bem-builder";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { OnboardingNextStepCard } from "@/v2/module/onboarding/next-step-card";
import { OnboardingProgressBar } from "@/v2/module/onboarding/progress-bar";
import { OnboardingQuickStartCard } from "../quick-start-card";
import { fetchBusinessOnboardingStatus } from "@/v2/repo/fetch-business-onboarding-status";
import { onboardingTrack } from "@/v2/module/onboarding/track";
import { EventEnum, OutletStateEnum } from "@/v2/enum";
import { fetchQRCode } from "@/v2/repo/fetch-qr-code";
import { OnboardingWelcomeBanner } from "@/v2/module/onboarding/welcome-banner";
import { OnboardingStateEnum } from "@/v2/module/onboarding/onboarding-state";
import { fetchOutlets } from "@/v2/repo/fetch-outlets";
import { OnboardingPublishBanner } from "@/v2/module/onboarding/publish-banner";
// @ts-ignore
import Menus from "@/api/menus";

const css = bemBuilder("onboarding-main");

export default defineComponent({
  name: "OnboardingMain",
  components: {
    AtomLoading,
    OnboardingProgressBar,
    MolClipboardCopy,
    OnboardingQuickStartCard,
    OnboardingNextStepCard,
    AtomButton,
    AtomText,
    OnboardingWelcomeBanner,
    MolGuideLink,
    OnboardingPublishBanner,
  },

  props: {
    businessId: {
      type: String,
      required: true,
    },
    isSignup: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const currentInstance = getCurrentInstance();
    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;
    const pageLoading = ref(false);
    const showRoadToSuccess = ref(false);
    const publishingBusiness = ref(false);
    const publishedBusiness = ref(false);
    const QRCodeLink = ref("");
    const steps = ref({
      outlet: OnboardingStateEnum.PENDING,
      branding: OnboardingStateEnum.PENDING,
      menu: OnboardingStateEnum.PENDING,
    });

    const nextStepsCard = [
      {
        heading: t("module.onboarding.next_steps.step_1.heading"),
        subheading: t("module.onboarding.next_steps.step_1.subheading"),
        route: "sales_channels.index",
        icon: "megaphone",
        estimate: 4,
        sucessClick: "sales_channels",
      },
      {
        heading: t("module.onboarding.next_steps.step_6.heading"),
        subheading: t("module.onboarding.next_steps.step_6.subheading"),
        route: "qr-code.digital-menu",
        icon: "qr-code",
        estimate: 2,
        sucessClick: "qr-code",
      },
      {
        heading: t("module.onboarding.next_steps.step_3.heading"),
        subheading: t("module.onboarding.next_steps.step_3.subheading"),
        route: "discounts.create",
        icon: "discount",
        estimate: 5,
        sucessClick: "discounts",
      },
      {
        heading: t("module.onboarding.next_steps.step_4.heading"),
        subheading: t("module.onboarding.next_steps.step_4.subheading"),
        route: "marketing.index",
        icon: "speech-bubble",
        estimate: 6,
        sucessClick: "sms_campaigns",
      },
      {
        heading: t("module.onboarding.next_steps.step_5.heading"),
        subheading: t("module.onboarding.next_steps.step_5.subheading"),
        route: "user-management.invite",
        icon: "users",
        estimate: 4,
        sucessClick: "user_management",
      },
    ];

    const currentBusiness = computed(() =>
      useHelpers().getCurrentBusiness(props.businessId)
    );
    const country = computed(() => currentBusiness.value?.country);
    const webviewLink = computed(() => {
      const { orderingLink } = currentBusiness.value;
      return {
        orderingLink,
        text: orderingLink.replace(/^(https:\/\/)?(http:\/\/)?/i, ""),
      };
    });
    const allStepsCompleted = computed(() => progress.value === "100");

    const progress = computed(() => {
      const states = Object.values(steps.value);
      const stepsCompleted = states.filter(
        (state) => state === OnboardingStateEnum.DONE
      );
      const progressMap: Record<number, string> = {
        0: "25",
        1: "50",
        2: "75",
        3: "100",
      };
      return progressMap[stepsCompleted.length];
    });

    async function getOutletsStatus(): Promise<OnboardingStateEnum> {
      try {
        const result = await fetchOutlets({
          businessId: props.businessId,
          perPage: 10,
          page: 0,
        });
        const { data } = result || {};

        if (!data.length) return OnboardingStateEnum.PENDING;

        return data.some(({ state }) => state === OutletStateEnum.ACTIVE)
          ? OnboardingStateEnum.DONE
          : OnboardingStateEnum.IN_PROGRESS;
      } catch {
        generalErrorToast();
        return OnboardingStateEnum.PENDING;
      }
    }

    async function getMenuStatus(): Promise<OnboardingStateEnum> {
      try {
        const result = await Menus.get(props.businessId, { page: 1 });
        const { data } = result || {};

        if (!data.length) return OnboardingStateEnum.PENDING;

        return data.some(({ state }: { state: string }) => state === "live")
          ? OnboardingStateEnum.DONE
          : OnboardingStateEnum.IN_PROGRESS;
      } catch {
        generalErrorToast();
        return OnboardingStateEnum.PENDING;
      }
    }

    async function getBrandingStatus(): Promise<OnboardingStateEnum> {
      try {
        const result = await fetchOnboardingSteps(props.businessId);
        const branding = result.find(
          ({ title }: { title: string }) => title === "customize_your_branding"
        );

        return branding?.completedAt != null
          ? OnboardingStateEnum.DONE
          : OnboardingStateEnum.PENDING;
      } catch {
        generalErrorToast();
        return OnboardingStateEnum.PENDING;
      }
    }

    async function isBusinessPublished(): Promise<Boolean> {
      try {
        const businessStatus = await fetchBusinessOnboardingStatus(
          props.businessId
        );
        const inProgress =
          businessStatus === "New" ||
          businessStatus === "Active Implementation";

        return !inProgress;
      } catch {
        generalErrorToast();
        return false;
      }
    }

    async function getQRCode() {
      try {
        QRCodeLink.value = await fetchQRCode(webviewLink.value.orderingLink);
      } catch {
        generalErrorToast();
      }
    }

    watch(
      () => props.businessId,
      async () => {
        pageLoading.value = true;

        const isPublished = await isBusinessPublished();

        if (isPublished) {
          showRoadToSuccess.value = true;
          publishedBusiness.value = true;

          pageLoading.value = false;
          return;
        }
        showRoadToSuccess.value = false;
        publishedBusiness.value = false;

        const [outlet, branding, menu] = await Promise.all([
          getOutletsStatus(),
          getBrandingStatus(),
          getMenuStatus(),
        ]);

        steps.value = { outlet, branding, menu };

        if (progress.value === "25") {
          onboardingTrack(
            analytics,
            EventEnum.ONBOARDING_FLOW_STARTED,
            props.businessId,
            {
              onboarding_progress: "25%",
            }
          );
        }

        pageLoading.value = false;
      },
      { immediate: true }
    );

    const onQuickStartCardClick =
      (eventName: EventEnum) => (state: OnboardingStateEnum) => {
        if (state === OnboardingStateEnum.DONE) {
          onboardingTrack(analytics, eventName, props.businessId);
        } else {
          const status =
            state === OnboardingStateEnum.IN_PROGRESS
              ? "in progress"
              : "not started";

          onboardingTrack(analytics, eventName, props.businessId, {
            onboarding_progress: `${progress.value}%`,
            status,
          });
        }
      };

    async function publishBusiness() {
      publishingBusiness.value = true;

      try {
        await updatePublishBusiness(props.businessId);

        new Toast().create({
          type: "success",
          title: t("module.onboarding.get_started.success_msg"),
        });
        currentInstance?.proxy.$store.dispatch("businesses/fetch");
        publishedBusiness.value = true;

        await getQRCode();

        onboardingTrack(
          analytics,
          EventEnum.ONBOARDING_FLOW_COMPLETED,
          props.businessId,
          {
            onboarding_progress: "100%",
          }
        );
      } catch {
        generalErrorToast();
      } finally {
        publishingBusiness.value = false;
      }
    }

    function openQRCodeInNewTab() {
      window.open(QRCodeLink.value, "_blank", "noopener, noreferrer");
      onboardingTrack(analytics, EventEnum.DOWNLOAD_QR_CODE, props.businessId);
    }

    const onRoadToSuccessClick = (name: string) => {
      onboardingTrack(
        analytics,
        EventEnum.ROAD_TO_SUCCESS_CLICKED,
        props.businessId,
        {
          road_to_success_module: name,
        }
      );
    };

    return {
      t,
      css,
      steps,
      country,
      progress,
      nextStepsCard,
      pageLoading,
      webviewLink,
      allStepsCompleted,
      publishingBusiness,
      publishedBusiness,
      QRCodeLink,
      showRoadToSuccess,

      onQuickStartCardClick,
      publishBusiness,
      openQRCodeInNewTab,
      onRoadToSuccessClick,

      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      MolGuideLinkArticleEnum,
      EventEnum,
    };
  },
});
