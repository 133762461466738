











































































import { t } from "@/i18n";
import { RawLocation } from "vue-router";
import {
  AtomIcon,
  AtomLink,
  AtomButtonLink,
  AtomLinkSizeEnum,
  AtomLinkTypeEnum,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import { OnboardingStateEnum } from "@/v2/module/onboarding/onboarding-state";

const css = bemBuilder("onboarding-quick-start-card");
export default defineComponent({
  name: "OnboardingQuickStartCard",
  components: {
    AtomText,
    AtomIcon,
    AtomButtonLink,
    AtomLink,
  },
  props: {
    state: {
      type: String as PropType<OnboardingStateEnum>,
      default: OnboardingStateEnum.PENDING,
    },
    isCompleted: {
      type: Boolean,
      default: true,
    },
    estimateMins: {
      type: Number,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
    subheading: {
      type: String,
      required: true,
    },
    buttonPendingText: {
      type: String,
      required: true,
    },
    buttonProgressText: {
      type: String,
      required: true,
    },
    linkText: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    creatingRoute: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
    continueRoute: {
      type: Object as PropType<RawLocation>,
      required: true,
    },
    onButtonClick: {
      type: Function as PropType<(state: OnboardingStateEnum) => void>,
      default: () => {},
    },
    onLinkClick: {
      type: Function as PropType<(state: OnboardingStateEnum) => void>,
      default: () => {},
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomLinkSizeEnum,
      AtomLinkTypeEnum,
      OnboardingStateEnum,
    };
  },
});
