import Api from "./index";
import MenuConstructor from "@/constructors/Menu";
import MenuCategoryConstructor from "@/constructors/MenuCategory";
import PaginationConstructor from "@/constructors/Pagination";

export default {
  /**
   * Get list of menus based in the passed params.
   *
   * @param {String} businessId
   * @param {Object} params
   * @returns {{ data: Array<MenuConstructor>, pagination: PaginationConstructor }}
   */
  async get(businessId, params = {}) {
    const res = await Api.get(`/api/v3/businesses/${businessId}/menus`, {
      params,
    });

    return {
      data: res.data.map((obj) => new MenuConstructor(obj)),
      pagination: new PaginationConstructor(res.meta),
    };
  },

  /**
    Fetch a menu.
   *
   * @param {String} businessId
   * @returns {MenuConstructor}
   */
  async find(businessId, menuId) {
    const res = await Api.get(
      `/api/v3/businesses/${businessId}/menus/${menuId}`
    );

    return new MenuConstructor(res.data);
  },

  /**
   * Create a menu.
   *
   * @param {String} businessId
   * @param {{ name: String, partner_id: String }} payload
   * @returns {MenuConstructor} The created menu.
   */
  async create(businessId, payload) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/menus`,
      payload
    );

    return new MenuConstructor(res.data);
  },

  /**
   * Update the given menu.
   *
   * @param {String} businessId
   * @param {String} menuId
   * @param {Object} payload
   * @returns {MenuConstructor} The update menu
   */
  async update(businessId, menuId, payload) {
    const res = await Api.put(
      `/api/v3/businesses/${businessId}/menus/${menuId}`,
      payload
    );

    return new MenuConstructor(res.data);
  },

  /**
   * Publish the given menu.
   *
   * @param {String} businessId
   * @param {String} menuId
   * @returns {MenuConstructor}
   */
  async publish(businessId, menuId) {
    const res = await Api.put(
      `/api/v3/businesses/${businessId}/menus/${menuId}/publish`
    );

    return new MenuConstructor(res.data);
  },

  /**
   * Remove the given menu.
   *
   * @param {String} businessId
   * @param {String} menuId
   * @returns {void}
   */
  async destroy(businessId, menuId) {
    await Api.delete(`/api/v3/businesses/${businessId}/menus/${menuId}`);
  },

  /**
   * Create a category to the given menu.
   *
   * @param {String} businessId
   * @param {String} menuId
   * @param {Object} payload
   * @returns {MenuCategoryConstructor} The created category
   */
  async addCategory(businessId, menuId, payload) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/menus/${menuId}/add-category`,
      payload
    );

    return new MenuCategoryConstructor(res.data);
  },

  /**
   * Rename the given category.
   *
   * @param {String} businessId
   * @param {String} categoryId
   * @param {{ name: String }} payload
   * @returns {MenuCategoryConstructor} The renamed category
   */
  async renameCategory(businessId, categoryId, payload) {
    const res = await Api.put(
      `/api/v3/businesses/${businessId}/categories/${categoryId}/rename`,
      payload
    );

    return new MenuCategoryConstructor(res.data);
  },

  /**
   * Remove the given category.
   *
   * @param {String} businessId
   * @param {String} categoryId
   * @returns {void}
   */
  async removeCategory(businessId, categoryId) {
    await Api.delete(
      `/api/v3/businesses/${businessId}/categories/${categoryId}`
    );
  },

  /**
   * Reorder categories as per list of ids.
   *
   * @param {String} businessId
   * @param {String} menuId
   * @param {Array<String>} categoriesIds
   * @returns {void}
   */
  async sortCategories(businessId, menuId, categoriesIds) {
    await Api.put(
      `/api/v3/businesses/${businessId}/menus/${menuId}/reorder-categories`,
      {
        categories_ids: categoriesIds,
      }
    );
  },

  /**
   * Attach items to the given category.
   *
   * @param {String} businessId
   * @param {String} categoryId
   * @param {Array<String>} itemsIds
   * @returns {MenuCategoryConstructor} The category resource with all of its modifiers
   */
  async attachItemsToCategory(businessId, categoryId, itemsIds) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/categories/${categoryId}/attach-items`,
      {
        items_ids: itemsIds,
      }
    );

    return new MenuCategoryConstructor(res.data);
  },

  /**
   * Remove items from the given category.
   *
   * @param {String} businessId
   * @param {String} categoryId
   * @param {Array<String>} itemsIds
   * @returns {MenuCategoryConstructor} The category resource with all of its modifiers
   */
  async removeItemsFromCategory(businessId, categoryId, itemsIds) {
    const res = await Api.post(
      `/api/v3/businesses/${businessId}/categories/${categoryId}/detach-items`,
      {
        items_ids: itemsIds,
      }
    );

    return new MenuCategoryConstructor(res.data);
  },

  /**
   * Reorder items from category as per list of ids.
   *
   * @param {String} businessId
   * @param {String} menuId
   * @param {Array<String>} itemsIds
   * @returns {void}
   */
  async sortItemsInCategory(businessId, categoryId, itemsIds) {
    await Api.put(
      `/api/v3/businesses/${businessId}/categories/${categoryId}/reorder-items`,
      {
        items_ids: itemsIds,
      }
    );
  },

  /**
   * Hide category for the given outlet id.
   *
   * @param {String} businessId
   * @param {String} categoryId
   * @param {String} outletId
   * @returns {void}
   */
  async hideCategoryFromOutlet(businessId, categoryId, outletId) {
    await Api.post(
      `/api/v3/businesses/${businessId}/categories/${categoryId}/hide`,
      {
        outlet_id: outletId,
      }
    );
  },

  /**
   * Make category visible for the given outlet id.
   *
   * @param {String} businessId
   * @param {String} categoryId
   * @param {String} outletId
   * @returns {void}
   */
  async makeCategoryVisibleToOutlet(businessId, categoryId, outletId) {
    await Api.post(
      `/api/v3/businesses/${businessId}/categories/${categoryId}/make-visible`,
      {
        outlet_id: outletId,
      }
    );
  },
};
