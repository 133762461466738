import { RepoErrorEnum } from "../repo-error.enum";
import { http } from "@/v2/repo/http";

interface IQRCodeRequestFormatted {
  inner_color?: string;
  logo_url?: string;
  logo_size?: number;
}

export interface IQRCodeRequest {
  innerColor?: string;
  logoSize?: number;
  logoURL?: string;
}

const payloadNormalize = (params?: IQRCodeRequest): IQRCodeRequestFormatted => {
  let additionalParams = {};

  if (params?.innerColor) {
    additionalParams = {
      inner_color: params?.innerColor,
    };
  }

  if (params?.logoURL && params?.logoSize) {
    additionalParams = {
      ...additionalParams,
      logo_url: params.logoURL,
      logo_size: params?.logoSize,
    };
  }

  return additionalParams;
};

export async function fetchQRCode(
  url: string,
  additionalParams?: IQRCodeRequest
): Promise<string> {
  try {
    const params = {
      url,
      ...payloadNormalize(additionalParams),
    };
    const { request } = await http.get("api/v3/qrcode/generate", { params });

    return request?.responseURL || "";
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }
}
