import { EventCategoryEnum, EventEnum } from "@/v2/enum/events";
import { IAnalytics } from "@/plugins/analytics/types";
import { useHelpers } from "@/v2/composable/use-helpers";

export function onboardingTrack(
  analytics: IAnalytics,
  eventName: EventEnum,
  businessId: string,
  additionalData: Record<string, any> = {}
): void {
  const business = useHelpers().getCurrentBusiness(businessId);

  analytics.trackEvent(eventName, {
    business_id: business.id,
    business_name: business.name,
    event_category: EventCategoryEnum.ONBOARDING,
    ...additionalData,
  });
}
